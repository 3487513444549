import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Button} from "reactstrap";
import CafeMusicStyle from "assets/jss/material-kit-pro-react/views/cafeMusicStyle.js";
const useStyles = makeStyles(CafeMusicStyle);

export default function TableRow({link, topic, authors}){
    const classes = useStyles();
    return(
    
                  <tr>
                    <td>
                    <div className={classes.mContainer}>
                        {link}
                    </div>
                    </td>
                    <td>
                    <div className={classes.mContainer}>
                      {topic}
                      </div>
                      </td>
                      <td>
                    <div className={classes.mContainer}>
                      {authors}
                      </div>
                      </td>
                    </tr>
             
       
    );
}

TableRow.defaultProps={
  playing:false
}