import React, {useState } from 'react';
import { useFetch } from './Hook.js'


import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    Button,
} from 'reactstrap';
import Info from './info.js';

import CafeHeader from "components/Header/CafeHeader.js";
import CafeFooter from "components/Footer/CafeFooter.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CafeWebStyle from "assets/jss/material-kit-pro-react/views/cafeWebStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(CafeWebStyle);

function Covid() {
    const [cdata, loading] = useFetch(
        "https://corona.lmao.ninja/v3/covid-19/all"
    );
    const [xdata, xloading] = useFetch(
        "https://corona.lmao.ninja/v3/covid-19/states"
    );
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    const [tcases, settcases] = useState(0);
    const [totald, set1] = useState(0);
    const [todayd, set2] = useState(0);
    const [r, set3] = useState(0);
    const [a, set4] = useState(0);
    
    const classes = useStyles();

    return (
        <div >
            <CafeHeader/>
<Parallax
        image={require("assets/img/bg3.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
                <div>
              <h2 className={classes.title}>COVID-19 STATISTICS</h2>
              <h3 className={classes.title}>Total Cases To Date:{"    "}{cdata.cases}</h3>
              </div>
            </GridItem>

          </GridContainer>
        </div>
      </Parallax>
            <br /><br />
            <div class="data">
                {
                    xdata.map((sdata, index) => {
                        return (
                            <GridContainer key={sdata.state}>
                                <GridItem>
                                <Info tcases={tcases} open={modal} ftog={toggle}
                                    totald={totald}
                                    todayd={todayd}
                                    r={r}
                                    a={a}
                                />
                                <Card className="my-5">
                                    <CardHeader>{sdata.state}</CardHeader>
                                    <CardBody>
                                        <CardTitle>Number Of Cases</CardTitle>
                                        <CardText>{sdata.cases}</CardText>
                                        <Button onClick={() => {
                                            settcases(sdata.todayCases);
                                            set1(sdata.deaths);
                                            set2(sdata.todayDeaths);
                                            set3(sdata.recovered);
                                            set4(sdata.active);
                                            toggle();
                                        }}>More Info</Button>
                                    </CardBody>
                                </Card>
                                </GridItem>
                            </GridContainer>
                        );
                    })
                }
            </div>
            <CafeFooter/>
        </div>
    );
}
export default Covid;