/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import ReactDOM from "react-dom";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import CafeLanding from "views/CafeLanding/CafeLanding.js";
import CafeGames from "views/CafeGames/CafeGames.js";
import CafeWeb from "views/CafeWeb/CafeWeb.js";
import CafeArt from "views/CafeArt/CafeArt.js";
import CafeMusic from "views/CafeMusic/CafeMusic.js";
import CafeSupport from "views/CafeSupport/CafeSupport.js";

import ErrorPage from "views/ErrorPage/ErrorPage.js";

//This is for student websites
import Covid from "views/CafeWeb/CafeSites/Covid/Covid.js";
import CssPractice from "views/CafeWeb/CafeSites/CssPractice/csspractice.js";
import MusicPlayer from "views/CafeWeb/CafeSites/MusicPlayer/MusicPlayer.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>      
      <Route path="/home" component={CafeLanding}/>
      <Route path="/games" component={CafeGames}/>
      <Route path="/webdesign" component={CafeWeb}/>
      <Route path="/art" component={CafeArt}/>
      <Route path="/music" component = {CafeMusic}/>
      <Route path="/support" component = {CafeSupport}/>

      <Route path="/webdesign-covid" component={Covid} />
      <Route path="/webdesign-css-practice" component={CssPractice}/>
      <Route path="/webdesign-music-player" component={MusicPlayer}/>


      <Redirect exact from='/' to='home'/>
      <Route path="*" component={ErrorPage}/>
    
    </Switch>
  </Router>,
  document.getElementById("root")
);
