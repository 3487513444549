/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CafeHeader from "components/Header/CafeHeader.js";
import CafeFooter from "components/Footer/CafeFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionInterested from "./Sections/SectionInterested.js";


import cafeGamesStyle from "assets/jss/material-kit-pro-react/views/cafeGamesStyle.js";

const useStyles = makeStyles(cafeGamesStyle);

export default function CafeGames() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <CafeHeader/>
      <Parallax image={require("assets/img/dg2.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
              <h2 className={classes.title}>
                Games - Made By Students
              </h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionInterested />
        </div>
      </div>
      <CafeFooter/>
    </div>
  );
}
