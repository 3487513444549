import React from 'react';
import dog from './dog.png';
import './website.css';

import CafeHeader from "components/Header/CafeHeader.js";
import CafeFooter from "components/Footer/CafeFooter.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CafeWebStyle from "assets/jss/material-kit-pro-react/views/cafeWebStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(CafeWebStyle);
function CSSPractice() {
    const classes = useStyles();

    return (
        <div>
            <CafeHeader/>
            <Parallax
        image={require("assets/img/bg0.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
                <div>
              <h2 className={classes.title}>CSS Practice</h2>
              <h3 className={classes.title}>Colors & Animation</h3>
              </div>
            </GridItem>

          </GridContainer>
        </div>
      </Parallax>
            <br />
            <div className="div1"></div>
            <br />
            <div className="div2"></div>
            <br />
            <div className="div3"></div>
            <br />
            <div className="div4"></div>
            <br />
            <br />

            <span className="myspan"> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div>
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div>
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div>
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div> 
                <div className="mydiv"></div>
                <div className="mydiv"></div> 
            </span>



            <br />
            <br />
            <img src={dog} className="shaking-img" alt="logo" />
            <br />
            <br />
            <img src={dog} className="fancy-img" alt="logo" />
        <CafeFooter/>
        </div>

    );

}
export default CSSPractice;