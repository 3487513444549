/*eslint-disable*/
import React,{useState, setState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Table, Button} from "reactstrap";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import CafeHeader from "components/Header/CafeHeader.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CafeFooter from "components/Footer/CafeFooter.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CafeMusicStyle from "assets/jss/material-kit-pro-react/views/cafeMusicStyle.js";
import TableRow from "./TableRow.js";
import Sound from "react-sound";
import Music from "./Music.js";

import AQuietThought from "assets/music/A_Quiet_Thought.mp3";
import LostFound from "assets/music/Lost_Found.mp3";



const useStyles = makeStyles(CafeMusicStyle);

export default function CafeMusic() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const [song, setSong] = useState(AQuietThought);
  const [opt, setOpt] = useState(Sound.status.STOPPED);
  const classes = useStyles();
  return (
    <div>
      <CafeHeader/>
      <Music song={song} opt={opt}/>


      <Parallax
        image={require("assets/img/dg1.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h2 className={classes.title}>Music - Made By Students</h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              <h3 className={classes.cardTitle}>Devs Cafe Music</h3>
              <Table hover striped>

                <thead>
                  <th></th>
                  <th className={classes.mContainer}>TRACK</th>
                  <th className={classes.mContainer}>GENRE</th>
                  <th className={classes.mContainer}>MOOD</th>
                  <th className={classes.mContainer}>INSTRUMENT</th>
                  <th className={classes.mContainer}>DURATION</th>
                </thead>
                
                <tbody>
                  <tr>
                    <td>
                      <Button onClick={() => { setSong(AQuietThought); setOpt(Sound.status.PLAYING);}}
                        className={classes.play} >
                        <PlayCircleFilled/>
                      </Button>
                      </td>
                  <TableRow
                    title= "A Quiet Thought"
                    author="Wayne Jones"
                    genre="Cinematic"
                    mood="Calm"
                    instrument="Piano"
                    duration="1:56"
                  />
                  </tr>

                  <tr>
                    <td>
                      <Button onClick={() => { setSong(LostFound); setOpt(Sound.status.PLAYING);}}
                        className={classes.play} >
                        <PlayCircleFilled/>
                      </Button>
                      </td>
                  <TableRow
                    title= "Lost & Found"
                    author="Dan Lebowitz"
                    genre="Country & Folk"
                    mood="Calm"
                    instrument="Guitar"
                    duration="2:20"
                  />
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
      <CafeFooter/>
    </div>
  );
}
