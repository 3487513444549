import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";

import bg10 from "assets/img/bg10.jpg";

import Corey from "assets/img/examples/Corey.jpg";
import Yuki from "assets/img/examples/Yuki.jpg";
import OAATStudio from "assets/img/examples/OAATStudio.jpg";
import sectionImageStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionImageStyle.js";

const useStyles = makeStyles(sectionImageStyle);

export default function SectionImage() {
  const classes = useStyles();
  return (

    <div
      id="instructors"
      className={classes.section}
      style={{ backgroundImage: "url(" + bg10 + ")" }}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <Card profile plain className={classes.textLeft}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>

                      <img src={Yuki} alt="..." />
             
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>Christopher Manloloyo</h4>
                    <Muted>
                      <h6>Software Developer / Instructor</h6>
                    </Muted>
                    <p className={classes.description}>
                      Chris is an Electrical Engineer that has interest in both
                      hardware and software. 
                    </p>
                  </CardBody>
                  <CardFooter profile plain>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Card profile plain className={classes.textLeft}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>
 
                      <img src={Corey} alt="..." />
  
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>Corey Chang</h4>
                    <Muted>
                      <h6>Software Developer / Instructor</h6>
                    </Muted>
                    <p className={classes.description}>
                      Corey is an Electrical Enginner and has a passion for 
                      2D/3D art and Photography.
                    </p>
                  </CardBody>
                  <CardFooter profile plain>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Card profile plain className={classes.textLeft}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>
                      <img src={OAATStudio} alt="..." />
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>Oahu Art & Tech (OAAT)</h4>
                    <Muted>
                      <h6>Business</h6>
                    </Muted>
                    <p className={classes.description}>
                      OAAT is dedicated teaching both art and technology. 
                      They offer fun lessons in  
                      Music, Art, Language, Culture, Robotics, & Programming. Vist their page {" "}
                      <a href="https://www.oahuartandtech.com/" target="_blank">here</a>
                    </p>
                  </CardBody>
                  <CardFooter profile plain>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
