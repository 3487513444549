import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import bg5 from "assets/img/bg5.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <h3 className={classes.title + " " + classes.textLeft}>
      You will be able to play some student made games soon. In the meantime,
        please enjoy these videos of games made by students.      </h3>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
            <iframe allow="fullscreen" src="https://drive.google.com/file/d/1jRpk4TuvEpOdID4-1_tNuVN5xShYwC07/preview"></iframe>
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h3>Boxy</h3>
              </Danger>
              <h4 className={classes.cardTitle}>by Private Student</h4>
              <h5 >
                  A short but fun platformer game. Boxy's has a very simple design but it's polished edges are just amazing.
              </h5>

            </CardBody>
          </Card>
        </GridItem>
        <br/>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
            <iframe allow="fullscreen" src="https://drive.google.com/file/d/1jlWHSnGUrBhg7om0golJ9t2IVcd9-xXT/preview"></iframe>
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h3>Get Me Out</h3>
              </Danger>
              <h4 className={classes.cardTitle}>by Private Student</h4>
              <h5>
                  Get Me Out is challenging but fun. Keep an eye out for a secret level.
              </h5>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
            <iframe allow="fullscreen" src="https://drive.google.com/file/d/1x1MY9P2_da9TCsbQNMuM7tc2Te_sld_o/preview"></iframe>
            </CardHeader>
            <CardBody plain>
            <Danger>
                <h3>Boxy Shooter</h3>
              </Danger>
              <h4 className={classes.cardTitle}>by Daniel, Evan, & Leo</h4>
              <h5>
                  Just a short classic sidescroller here. Just make sure to pay attention to that boss at the end.
              </h5>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
