import React from 'react';
import Sound from 'react-sound';


class Music extends React.Component {
    render() {
        return <Sound
            url={this.props.song}
            playStatus={this.props.opt}
            playFromPosition={0 /* in milliseconds */}
            loop={true}
            onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
            onLoad={() => console.log('Loaded')}
            onPause={() => console.log('Paused')}
            onResume={() => console.log('Resumed')}
            onStop={() => console.log('Stopped')}
        />;
    }
}
export default Music;