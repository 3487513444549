/*eslint-disable*/
import React,{useState, setState} from "react";
import { Switch, Route, Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Table, Button} from "reactstrap";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import CafeHeader from "components/Header/CafeHeader.js";
import CafeFooter from "components/Footer/CafeFooter.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CafeWebStyle from "assets/jss/material-kit-pro-react/views/cafeWebStyle.js";
import TableRow from "./TableRow.js";




const useStyles = makeStyles(CafeWebStyle);

export default function CafeWeb() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  return (
    <div>
      <CafeHeader/>

      <Parallax
        image={require("assets/img/dg3.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h2 className={classes.title}>Web Design - Made By Students</h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              <h3 className={classes.cardTitle}>Select A Link To View Student Pages</h3>
              <Table hover striped>

                <thead>

                  <th className={classes.mContainer}></th>
                  <th className={classes.mContainer}>Topic</th>
                  <th className={classes.mContainer}>Authors</th>
                </thead>
                <tbody>
                  <TableRow
                    link= {<Link to="/webdesign-covid"> View Website </Link>}
                    topic="COVID-19 Tracker"
                    authors="Daniel & Evan"
                  />
                  <TableRow
                    link= {<Link to="/webdesign-css-practice"> View Website </Link>}
                    topic="CSS Animations"
                    authors="Daniel & Evan"
                  />
                  <TableRow
                    link= {<Link to="/webdesign-music-player"> View Website </Link>}
                    topic="Music & CSS"
                    authors="Daniel & Evan"
                  />
                  
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
      <CafeFooter/>
    </div>
  );
}
