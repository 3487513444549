/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// react router hash link for anchors
import {HashLink} from "react-router-hash-link";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ShopTwoTone from "@material-ui/icons/ShopTwoTone";
import Storage from "@material-ui/icons/Storage";
import Web from "@material-ui/icons/Web";
import SportsEsports from "@material-ui/icons/SportsEsports";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ListIcon from "@material-ui/icons/List";
import ImageOutlined from "@material-ui/icons/ImageOutlined";
import MusicNote from "@material-ui/icons/MusicNote";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";
import Error from "@material-ui/icons/Error";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/home") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop ,500);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Browse"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Storage}
          dropdownList={[
            <HashLink 
              smooth
              to="/home"
              className={classes.dropdownLink}>
              <LineStyle className={classes.dropdownIcons} /> Home
            </HashLink>,
            <HashLink 
              smooth
              to="/home#projects"
              className={classes.dropdownLink}>
              <LineStyle className={classes.dropdownIcons} /> Student Projects
            </HashLink>,
            <HashLink
              smooth 
              to="/home#instructors" 
              className={classes.dropdownLink}>
              <Layers className={classes.dropdownIcons} />
              Instructors
            </HashLink>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Projects"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link
              to="/games"
              className={classes.dropdownLink}
            >
              <SportsEsports className={classes.dropdownIcons} /> Game Design
            </Link>,
            <Link
            to="/webdesign"
            className={classes.dropdownLink}
          >
            <Web className={classes.dropdownIcons} /> Web Design
          </Link>,
            <Link
              to="/art"
              className={classes.dropdownLink}
            >
              <ImageOutlined className={classes.dropdownIcons} /> Digital Art
            </Link>,
            <Link
              to="/music"
              className={classes.dropdownLink}
            >
              <MusicNote className={classes.dropdownIcons} /> Music
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <a href="mailto:devscafe.contact@gmail.com" className={classes.navLink}>
        <ContactSupportIcon />Contact
        </a>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Link to="/support">
        <Button
          color={window.innerWidth < 960 ? "info" : "white"}
          target="_blank"
          className={classes.navButton}
          round
        >
          <ShopTwoTone className={classes.icons} /> Support Us
        </Button>
        </Link>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
