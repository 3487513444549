import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Button} from "reactstrap";
import CafeMusicStyle from "assets/jss/material-kit-pro-react/views/cafeMusicStyle.js";
const useStyles = makeStyles(CafeMusicStyle);

export default function TableRow({title,playing,author,genre,mood,instrument,duration}){
    const classes = useStyles();
    return(
    
                  <>
                    <td>
                    <div className={classes.mContainer}>
                        {title}
                      <br />
                        by {author}
                      </div>
                    </td>
                    <td>
                    <div className={classes.mContainer}>
                      {genre}
                      </div>
                      </td>
                    <td>{mood}</td>
                    <td>
                    <div className={classes.mContainer}>
                      {instrument}
                      </div>
                    </td>
                    <td>
                    <div className={classes.mContainer}>
                     {duration}
                      </div>
                    </td>
                    </>
             
       
    );
}

TableRow.defaultProps={
  playing:false
}