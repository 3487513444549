/*eslint-disable*/
import React,{useState, setState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Table, Button} from "reactstrap";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import SectionMethods from "./Sections/SectionMethods.js";
import CafeHeader from "components/Header/CafeHeader.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CafeFooter from "components/Footer/CafeFooter.js";

import CafeSupportStyle from "assets/jss/material-kit-pro-react/views/cafeSupportStyle.js";
const useStyles = makeStyles(CafeSupportStyle);

export default function CafeSupport() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  return (
    <div>
      <CafeHeader/>

      <Parallax
        image={require("assets/img/examples/card-project4.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h2 className={classes.title}>Support Us</h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
         <SectionMethods/>
        </div>
      </div>
      <CafeFooter/>
    </div>
  );
}
