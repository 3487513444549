import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';

function Info({ tcases, open, ftog, totald, todayd, r, a}) {
    return (
        <div>
            <Modal isOpen={open} toggle={ftog}>
                <div classname="mheader">
                <ModalHeader>More Info</ModalHeader>
                </div>
                <div className="mbody">
                <ModalBody >
                    <h5>Today's Cases: {tcases} </h5>
                    <h5>Total Deaths: {totald} </h5>
                    <h5>Today's Deaths: {todayd} </h5>
                    <h5>Recovered: {r} </h5>
                    <h5>Active: {a} </h5>
                </ModalBody>
                </div>
                <ModalFooter>
                    <Button color="secondary" onClick={ftog}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default Info;