import React from "react";
import Header from "./Header.js";
import HeaderLinks from "./HeaderLinks.js";

export default function CafeHeader(){
    return(
        <Header
        brand="Devs Cafe"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "rose"
        }}
      />
    );
}

