import './website.css';
import React, { useState, setState } from 'react';

import {
    Button,
    Col,
    Row,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from 'reactstrap';


import SoundComp from './SoundComp.js';
import Sound from 'react-sound';
import Sample from './Files/sample.mp3';
import Meadow from './Files/Meadow.mp3';
import Ticker from './Files/Ticker.mp3';
import Triumph from './Files/Triumph.mp3';

import CafeHeader from "components/Header/CafeHeader.js";
import CafeFooter from "components/Footer/CafeFooter.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CafeWebStyle from "assets/jss/material-kit-pro-react/views/cafeWebStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(CafeWebStyle);

function MusicPlayer() {
    const [song, setSong] = useState(Sample);
    const [opt, setOpt] = useState(Sound.status.STOPPED);
    const [title, setTitle] = useState("Sample.mp3")
    
    const classes = useStyles();

    return (
        <div >
            <CafeHeader/>
            <Parallax
        image={require("assets/img/bg9.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
                <div>
              <h2 className={classes.title}>Bulding A Simple Music</h2>
              <h2 className={classes.title}>Player Using CSS</h2>
              </div>
            </GridItem>

          </GridContainer>
        </div>
      </Parallax>
            <div>
            <SoundComp song={song} opt={opt}/>
            <br/><br/>
            <Col className="player">
                <Row>
                    <p className="centered">{title}</p>
                </Row>
                
                <Row>
                    <span className={(opt===Sound.status.PLAYING) ? "myspan centered":"myspan-stop centered"}>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                        <div className="mydiv"></div>
                    </span>
                </Row>
                <Row>
                    <div  className="centered">
                    <Button color="dark"> {"<<"}</Button>
                    <Button color="warning" onClick={()=>setOpt(Sound.status.STOPPED)}>{'stop'}</Button>{' '}
                    <Button color="warning"onClick={()=>setOpt(Sound.status.PLAYING)}>{'play'}</Button>{' '}
                    <Button color="dark"> {">>"}</Button>

                    </div>
                </Row>
                <Row>
                    <div className="centered">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="danger">
                            Select Song
                             </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem tag={Button} onClick={() => { setSong(Sample); setTitle("Sample.mp3"); setOpt(Sound.status.STOPPED); }}>sample</DropdownItem>
                            <DropdownItem tag={Button} onClick={() => { setSong(Meadow); setTitle("Meadow.mp3"); setOpt(Sound.status.STOPPED); }}>Meadow</DropdownItem>
                            <DropdownItem tag={Button} onClick={() => { setSong(Ticker); setTitle("Ticker.mp3"); setOpt(Sound.status.STOPPED); }}>Ticker</DropdownItem>
                            <DropdownItem tag={Button} onClick={() => { setSong(Triumph); setTitle("Triumph.mp3"); setOpt(Sound.status.STOPPED); }}>Triumph</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    </div>
                </Row>
                
                <Row><br/></Row>
                
                <Row><br/></Row>
            </Col>
            <CafeFooter/>
            </div>
        </div>

    );

}
export default MusicPlayer;