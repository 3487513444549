import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";

import buddy from "assets/img/cafeart/buddy.jpg";
import spaceship1 from "assets/img/cafeart/spaceship1.png";
import well from "assets/img/cafeart/well.png";
import kyrak from "assets/img/cafeart/kyrak.png";
import hospital from "assets/img/cafeart/hospital.png";
import spaceship0 from "assets/img/cafeart/spaceship0.png";

const useStyles = makeStyles(styles);

export default function SectionLatestArt() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2>Latest Art</h2>
        <GridContainer>
          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                  <img src={buddy} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${buddy})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Buddy by Private Student</h4>
                <p className={classes.cardDescription}>
                  Made in blender by a student.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          
          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                  <img src={spaceship1} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${spaceship1})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>SpaceShip by Private Student</h4>
                <p className={classes.cardDescription}>
                  Made in blender by a student.
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                  <img src={well} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${well})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Wishing Well by Private Student</h4>
                <p className={classes.cardDescription}>
                  Made in blender by a student.
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                  <img src={kyrak} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${kyrak})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Kyrak Monster by Private Student</h4>
                <p className={classes.cardDescription}>
                  Made in blender by a student.
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
              <iframe allow="fullscreen" src="https://drive.google.com/file/d/1MUoJaIC7-Z_L6Li-xUIJA9aup3ZE2A8N/preview"></iframe>
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Space Bus by Private Student</h4>
                <p className={classes.cardDescription}>
                  Made in blender by a student.
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                  <img src={hospital} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${hospital})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Hospital by Private Student</h4>
                <p className={classes.cardDescription}>
                  Made in blender by a student.
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                  <img src={spaceship0} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${spaceship0})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Space Bus by Private Student</h4>
                <p className={classes.cardDescription}>
                  Made in blender by a student.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          
          
          
        </GridContainer>
      </div>
    </div>
  );
}
