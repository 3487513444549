import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer.js";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import cafeLandingStyle from "assets/jss/material-kit-pro-react/views/cafeLandingStyle.js";

const useStyles = makeStyles(cafeLandingStyle);

export default function CafeFooter(){
    const classes = useStyles();

    return(
        <Footer
            content={
            <div>
                <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "}
                <a
                    href="https://www.htechstudio.com"
                    target="_blank"
                >
                    H Tech Studio
                </a>{" "}
                <Favorite className={classes.icon} />
                </div>
            </div>
            }/>
    );
}