/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CafeHeader from "components/Header/CafeHeader.js";
import CafeFooter from "components/Footer/CafeFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionPills from "./Sections/SectionPills.js";
import SectionImage from "./Sections/SectionImage.js";
import SectionPreFooter from "./Sections/SectionPreFooter.js";

import cafeLandingStyle from "assets/jss/material-kit-pro-react/views/cafeLandingStyle.js";

const useStyles = makeStyles(cafeLandingStyle);

export default function CafeLanding() {
    React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    });


  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 500);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const classes = useStyles();

  return (
    <div>
      <CafeHeader/>
      <Parallax image={require("assets/img/bg2.jpg")} filter="dark" small>
      <div className={classes.container}>
          <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.textIndent}>
              <h2 className={classes.title}>
                Devs Cafe: A Student Showcase
              </h2>
              <h3>
                The content on this website is posted by Instructors but
                made by the students. 
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>

          <SectionPills/>
        </div>
        <SectionImage/>
        <SectionPreFooter/>
      </div>     
      <CafeFooter/>
    </div>
  );
}
