import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Row, Col} from "reactstrap";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import Venmo from "assets/img/Venmo.png";
import Paypal from "assets/img/Paypal.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionMethods() {
  
  const classes = useStyles();
  
  return (
    <div className={classes.section}>
        <h3>
        We need your help today because our class sizes are growing fast. Your small gift 
        will help give everyone of our students a chance to learn and improve their programming. 
        Giving is very simple and 100% of your gift will instantly be used towards students in need. 
        Be one of the many sponsors who are kind enough to give. We are loyal to our contributors which means 
        we will never share your private information with any Third parties and we will not 
        harass you with emails or calls.
        Thank you so much for all the support. It really means a lot to us. Our developers work 
        hard to prepare our future programmer. We hope that you enjoy all of their content.
      </h3>

      <h3> To support us, you can click on the give button below to or you can <strong>Venmo</strong> us driectly <strong>@HTechStudio</strong></h3>
      <div >
        <form  action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="NKG6NSANNNDLJ" />
        <input style={{width:"50%"}} type="image" src="https://dl.dropbox.com/s/fisg1vugqxrebm2/giveButton.png?dl=0" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
      
    </div>
  );
}
