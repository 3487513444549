import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div id="projects"/>
      <h2 className={classes.header}>Student Projects</h2>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + cardProject2 + ")" }}
          >
            <CardBody background>
                <h3 className={classes.cardTitle}>
                  Game Design
                </h3>
              <h4 className={classes.category}>
                Students learn basic programming and then apply 
                their new found knowledge to game design.
              </h4>
              <Link to="/games">
              <Button round color="danger">
                <FormatAlignLeft className={classes.icons} /> Play Games
              </Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + cardProject1 + ")" }}
          >
            <CardBody background>
                <h3 className={classes.cardTitle}>
                  Web Design
                </h3>
              <h4 className={classes.category}>
                Students study modern technologies to learn how 
                webpages are built and create amazing content.
              </h4>
              <Link to="/webdesign">
              <Button round color="primary">
                <FormatAlignLeft className={classes.icons} /> View Web Projects
              </Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + cardProject5 + ")" }}
          >
            <CardBody background>
                <h4 className={classes.cardTitle}>
                  Digital Art
                </h4>
              <p className={classes.category}>
                Sudents learn a variety of tools to create both 2D and 3D 
                art. 
              </p>
              <Link to="/art">
              <Button round color="info">
                <FormatAlignLeft className={classes.icons} /> View Art
              </Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + cardProject6 + ")" }}
          >
            <CardBody background>
              <a href="#pablo">
                <h4 className={classes.cardTitle}>
                  Music
                </h4>
              </a>
              <p className={classes.category}>
                Students share their own music creations. 
              </p>
              <Link to="/music">
              <Button round href="#pablo" color="success">
                <FormatAlignLeft className={classes.icons} /> Listen to Music
              </Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
